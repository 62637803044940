// export const BASE_URL = "http://localhost:5000/api/v1/";

// export const FILE_PATH = "http://localhost:5000/";

// export const BASE_URL = "https://node.projects.codeify.co.in/humstudy/api/v1/";

// export const FILE_PATH = "https://node.projects.codeify.co.in/humstudy/uploads/";

export const BASE_URL = "https://node.humstudy.com/api/v1/";

export const FILE_PATH = "https://node.humstudy.com/uploads/";
