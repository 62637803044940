const menuItems = [
    {
      title: "Home",
      url: "",
      cName: "nav-links"
    },
    {
      title: "About Us",
      url: "about",
      cName: "nav-links"
    },
    {
      title: "Blogs",
      url: "blogs",
      cName: "nav-links"
    },
    // {
    //   title: "Services",
    //   url: "",
    //   cName: "nav-links"
    // },
    // {
    //   title: "Sign up",
    //   url: "signup",
    //   cName: "nav-links-mobile"
    // }
  ];
  
  export default menuItems;
  