import React from "react";
import "./BestService.css";

function BestService() {
  return (
    <div className="bestService-section">
      <div className="bestService container-xl mt-3">
        <div className="row bestService-row">
          <div className="col-12 col-lg-6 bestService-left">
            <img
              src={require("../../assets/images/customer.jpg")}
              alt="vision"
            />
          </div>
          <div className="col-12 col-lg-6 bestService-right pt-3">
            <h2>We Take you to the Best Service</h2>
            <p>
              With Humstudy, students can trust in certified services that truly
              support their journey.
            </p>
            <div className="best-services">
              <div className="best-service">
                <h1>100k+</h1>
                <p>
                  Courses <br /> WorldWide
                </p>
              </div>
              <div className="best-service">
                <h1>20k+</h1>
                <p>
                  Students <br /> WorldWide
                </p>
              </div>
              <div className="best-service">
                <h1>10k+</h1>
                <p>
                  Beds <br /> WorldWide
                </p>
              </div>
              <div className="best-service">
                <h1>4.9/5</h1>
                <p>
                  Overall <br/> Rating
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestService;
