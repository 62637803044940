import React from "react";
import "./VisionMission.css";

function VisionMission() {
  const content = [
    "All Universities Worldwide",
    "10,000 Student Worldwide.",
    "10,000 Beds Worldwide",
  ];
  return (
    <div className="visionMission-section">
      <div className="visionMission container-xl  mb-3">
        <div className="row vision-row">
          <div className="col-12 col-lg-6 visionMission-left">
            <img src={require("../../assets/images/team.jpg")} alt="vision" />
          </div>
          <div className="col-12 col-lg-6 visionMission-right pt-3">
            <h2>Our Vision</h2>
            <p>
              Our vision is to simplify the process of studying abroad for
              everyone, offering reliable and comprehensive support with
              end-to-end services, all on the Humstudy platform. We aim to
              empower students to confidently achieve their goals by providing
              trusted assistance with every aspect, including English
              proficiency exam preparation, loans, scholarships, visa support,
              and more—all without charging exorbitant fees.
            </p>
            {/* <div className="mt-4 d-flex flex-wrap justify-content-center items-center">
              {content?.map((elem) => {
                return <span className="bg-tl_primary text-light p-2 mx-2 mt-2 rounded font-semibold">{elem}</span>;
              })}
            </div> */}
            <br />
          </div>
        </div>
      </div>
      <div className="visionMission container-xl mt-5 mt-sm-3">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-6 visionMission-right pt-3">
            <h2>Our Mission</h2>
            <p>
              Our mission is to empower students worldwide to achieve their
              academic and career goals through exceptional study abroad
              experiences. We believe that education knows no boundaries, and by
              crossing borders, students can unlock their full potential, gain
              global perspectives, and develop the skills needed to thrive in a
              rapidly changing world.
            </p>
            <br />
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-end visionMission-left">
            <img src={require("../../assets/images/team.jpg")} alt="vision" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionMission;
