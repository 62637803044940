import React from "react";
import "./TrendingSearches.css";

function TrendingSearches() {
  return (
    // <div className='trending-searches-section'>
    //       <div className='trending-searches container-xl'>
    //           <h4>Trending Searches</h4>
    //           <div className='row mb-3'>
    //               <div className='col-6 col-md-3'>
    //                   <div className='trending-searches-content'>
    //                       <h6>Universities</h6>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                   </div>
    //               </div>
    //               <div className='col-6 col-md-3'>
    //                   <div className='trending-searches-content'>
    //                       <h6>Universities</h6>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                   </div>
    //               </div>
    //               <div className='col-6 col-md-3'>
    //                   <div className='trending-searches-content'>
    //                       <h6>Universities</h6>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                   </div>
    //               </div>
    //               <div className='col-6 col-md-3'>
    //                   <div className='trending-searches-content'>
    //                       <h6>Universities</h6>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                       <p>Top Universities in USA</p>
    //                   </div>
    //               </div>
    //           </div>
    //     </div>
    // </div>
    <></>
  );
}

export default TrendingSearches;
