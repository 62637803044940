import React from "react";
import "./FoundingStory.css";

function FoundingStory() {
  return (
    <div className="about-section fluid-container">
      <div className="about container-xl">
        <h2>Our Founding Story</h2>
        <div className="row mt-2 about-row gy-3">
          <div className="col-12 col-md-7 about-left">
            <p>
              Established in 2020 by Imran Khan, HumStudy provides consultation
              for overseas education and more than 10,000+ students have taken
              free counselling for overseas education. Humstudy represents 700+
              universities in the USA, UK, Australia, Canada, Ireland, and
              France. We have well qualified & experienced team and have given
              100% visa success to our students, who have enrolled in several
              top universities in the world.
            </p>
            <p>
              We have clients from Chennai, Gujarat, Delhi, Mumbai, Hyderabad,
              and all around India including Nepal , Bangladesh and south
              Africa. We have a lot of young, trained, and professional people
              in our team who work to support students in visa applications, SOP
              content writing, essay writing, admission support and everything
              related study abroad journey.
            </p>
          </div>
          <div className="col-11 col-sm-8 col-md-5 ">
            <img
              className="about-img w-100"
              src={require("../../assets/images/about.png")}
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoundingStory;
