import { FlightForm } from "../components/flightSearch/FlightForm";
import Navbar from "../components/ui/Navbar";

export const FlightsEnquiry = () => {
  return (
    <>
    <Navbar/>
      <FlightForm/>
    </>
  );
};
