import React, { useState } from "react";
import Modal from "./Modal";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import axios from "axios";
import { BASE_URL } from "../../utils/apiConfig";
import { toast } from "react-toastify";

const EnquiryModal = ({ setIsModal }) => {
  const [formData, setFormData] = useState({
    phoneNum: "",
    subject: "",
    message: "",
    purpose: "",
  });

  const options = ["Flight", "Visa", "University", "Accomodations", "Loans"];

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}admin/support/add-query`,
        formData
      );
      // Handle success (e.g., show a success message, close the modal, etc.)
      toast.success(res?.data?.message);
    } catch (err) {
      console.log(err);
      // Handle error (e.g., show an error message)
    }
  };

  // Inline styles for the modal
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1050, // Ensure it is above other content
    },
    content: {
      backgroundColor: "#fff", // White background for the modal content
      borderRadius: "0.3rem",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      maxWidth: "500px",
      width: "100%",
      padding: "1rem",
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
  };

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <button
          type="button"
          style={modalStyles.closeButton}
          aria-label="Close"
          onClick={() => setIsModal(false)}
        ></button>
        <div className="modal-header">
          <h5 className="modal-title mb-2">Fill the form</h5>
        </div>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              <label htmlFor="phoneNum" className="form-label">
                Contact Number
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNum"
                name="phoneNum"
                value={formData.phoneNum}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleInput}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="queryPurpose" className="form-label">
                Select Query Purpose
              </label>
              <select
                onChange={handleInput}
                className="form-select"
                id="queryPurpose"
                name="purpose"
              >
                {options.map((elem, index) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary m-2"
            onClick={() => setIsModal(false)}
          >
            Close
          </button>
          <button onClick={handleSave} className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnquiryModal;
