import React from "react";
import "./CustomerReview.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1115 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1114, min: 751 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const reviewsData = [
  {
    personName: "Akshi Dhall",
    personImg: "/assets/images/Akshi Dhall .jpg",
    review:
      "Best counselling services for education in Canada.The counsellors are incredibly supportive and are by your side during every step of the procedure.",
  },
  {
    personName: "Naurana Sehrawat",
    personImg: "/assets/images/Naurana Sherawat .jpg",
    review:
      "Excellent and most trusted.Really great at their job,student friendly.",
  },
  {
    personName: "Vansh Deep",
    personImg: "/assets/images/Vansh deep.jpg",
    review:
      "I have been looking for plenty of institutes for overseas education and study visa, but Humstudy is the most genuine institute amongst all i looked after. They provided me the best pathway for my Higher education in Australia. They have the best counsellors for education in Australia. Highly recommended and very satisfied from the services received.",
  },
  {
    personName: "Mansi Gupta",
    personImg: "/assets/images/Mansi Gupta .jpg",
    review:
      "Humstudy Overseas Education is one of the best in Delhi where you can go and put your complete trust in them. They are not a huge company but they are very well equipped with their services and quality because Humstudy Overseas Education Consultancy has provided me a lot of information regarding my UK study process without charging a single penny to me. If you are very serious about your foreign education, then Humstudy Overseas Education Consultancy is the best option in Delhi. Otherwise, lots of consultancies are waiting to grab your money. Humstudy Overseas Education Consultancy is trustworthy and reliable.",
  },
];

function CustomerReview() {
  return (
    <div className="customerReview-section container-fluid">
      <div className="customerReview container-xl">
        <div className="row pb-3">
          <div className="col-12 col-lg-6 customerReview-left">
            <h1>What Our Customer Say About Us ?</h1>
          </div>
          <div className="col-12 col-lg-6 customerReview-right">
            <h5>
              If you don’t trust us enough, take a look at the reviews from some
              of our users below. We hope it can help you in making your
              decision.
            </h5>
          </div>
        </div>

        <div className="row">
          <Carousel
            autoPlay={true}
            minimumTouchDrag={20}
            autoPlaySpeed={3000}
            className="mt-4"
            infinite={true}
            removeArrowOnDeviceType={["mobile"]}
            responsive={responsive}
            partialVisbile={true}
          >
            {reviewsData?.map((item, index) => {
              return (
                <div key={index} className="col-12 p-2 h-5">
                  <div className="customerReview-card card">
                    <p className="ReviewScroller">{item?.review}</p>
                    <hr style={{ borderColor: "#fff", margin: "10px 0" }} />
                    <div className="customerReview-cardBottom">
                      <img src={item?.personImg} alt="customer" />
                      <p>{item?.personName}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default CustomerReview;
