import React from "react";
import "./ChooseUs.css";

function ChooseUs() {
  return (
    <div className="chooseUs-section container-fluid">
      <div className="chooseUs container-xl">
        <h2>Why Choose Us</h2>
        <div className="row mt-2">
          <div className="col-12 col-lg-5 mt-3">
            <div className="choose-contents">
              <div className="choose-content">
                <button>1</button>
                <h4>
                  We provide the best choice of accommodation for you to stay.
                </h4>
                <p>
                  With Humstudy, you can find verified properties through our
                  trusted partners, ensuring comfort and satisfaction.
                </p>
              </div>

              <div className="choose-content">
                <button>2</button>
                <h4>
                  University Shortlisting and Course Suggestions with
                  Personalized Service.
                </h4>
                <p>
                  We offer fully customized services tailored to your
                  preferences, provided by professionals with 10 years of
                  experience.
                </p>
              </div>

              <div className="choose-content">
                <button>3</button>
                <h4>Students trust and experience</h4>
                <p>
                  We have placed over 10,000 students and guided them toward a
                  better future with our trusted services.
                </p>
              </div>
              <div className="choose-content">
                <button>4</button>
                <h4>End to end support from beginning to end</h4>
                <p>
                Everything related to your studies is taken care of, including loans, scholarships, flight tickets,
                the application process, visa support and Everything.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 mt-3 col-lg-7 choose-right d-flex justify-content-end">
            <img
              className="choose-img"
              src={require("../../assets/images/choosebg.jpg")}
              alt="choose"
            />
            <img
              className="choose-sm-img"
              src={require("../../assets/images/choosesmall.jpg")}
              alt="choose"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
