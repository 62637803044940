import React, { useState } from "react";
import "./News.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";

function News() {
  const [active, setActive] = useState(1);

  return (
    <div className="news-section">
      <div className="news container-xl">
        <h4>Humstudy in News</h4>
        <div className="row mt-4">
          <div className="col-12 col-md-4">
            <div className="news-tabs">
              <div
                onClick={() => setActive(1)}
                className={active === 1 ? "news-tab active" : "news-tab"}
              >
                <img
                  className="ht-logo"
                  src="/assets/images/IMG_4106.JPG"
                  alt="ht"
                />
                <KeyboardArrowRightIcon />
              </div>
              <div
                onClick={() => setActive(2)}
                className={active === 2 ? "news-tab active" : "news-tab"}
              >
                <img
                  className="ht-logo"
                  src="/assets/images/IMG_5599.PNG"
                  alt="indian express"
                />
                <KeyboardArrowRightIcon />
              </div>
              <div
                onClick={() => setActive(3)}
                className={active === 3 ? "news-tab active" : "news-tab"}
              >
                <img
                  className="ht-logo"
                  src="/assets/images/IMG_5601.PNG"
                  alt="ht"
                />
                <KeyboardArrowRightIcon />
              </div>
              <div
                onClick={() => setActive(4)}
                className={active === 4 ? "news-tab active" : "news-tab"}
              >
                <img
                  className="ht-logo"
                  src="/assets/images/IMG_5602.PNG"
                  alt="ht"
                />
                <KeyboardArrowRightIcon />
              </div>
              <div
                onClick={() => setActive(5)}
                className={active === 5 ? "news-tab active" : "news-tab"}
              >
                <img
                  className="ht-logo"
                  src="/assets/images/IMG_8446.PNG"
                  alt="ht"
                />
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 news-right">
            {active === 1 && (
              <div className="news-content">
                <h5>
                  {" "}
                  Humstudy Prepares to Dive into the Realm of 1 Million Students
                  and an $80 Billion Business of Studying Abroad...
                </h5>
                <p>
                  In the ever-evolving landscape of overseas education,
                  Humstudy, a bootstrap Edtech company, is gearing up for a
                  monumental leap into the realm of 1 million students...
                </p>
                <Link
                  className="text-tl_primary font-semibold text-decoration-none"
                  to={
                    "https://english.newsnationtv.com/india/news/humstudy-prepares-to-dive-into-the-realm-of-1-million-students-and-an-80-billion-business-of-studying-abroad-259599.html"
                  }
                >
                  Read more
                </Link>
              </div>
            )}
            {active === 2 && (
              <div className="news-content">
                <h5>
                  {" "}
                  Humstudy Emerges As Premier Choice For Indian Students Seeking
                  Abroad Education...
                </h5>
                <p>
                  {" "}
                  Humstudy has played a pivotal role in addressing this demand,
                  offering free counseling services to over 20,000 students.
                  Remarkably, a substantial portion of these students has
                  successfully secured...
                </p>
                <Link
                  className="text-tl_primary font-semibold text-decoration-none"
                  to={
                    "https://www.outlookindia.com/business-spotlight/humstudy-emerges-as-premier-choice-for-indian-students-seeking-abroad-education-news-327854"
                  }
                >
                  Read more
                </Link>
              </div>
            )}
            {active === 3 && (
              <div className="news-content">
                <h5>
                  {" "}
                  FROM MBA DROPOUT TO SUCCESSFUL ENTREPRENEUR: HOW HUMSTUDY
                  FOUNDER IMRAN KHAN TURNED ZERO INVESTMENT INTO A 50 LAKH
                  BUSINESS{" "}
                </h5>
                <p>
                  In the competitive world of startups, where securing funding
                  often seems like the only path to success, Imran Khan, the
                  founder of Humstudy, defied the norm. He embarked on a
                  remarkable journey, starting with nothing but an idea,
                  determination, and a willingness to invest his time wisely...
                </p>
                <Link
                  className="text-tl_primary text-decoration-none font-semibold"
                  to={
                    "https://yourstory.com/press-release/from-mba-dropout-to-successful-entrepreneur-how-humstudy-founder-imran-khan-turned-zero-investment-into-a-50-lakh-business"
                  }
                >
                  Read more
                </Link>
              </div>
            )}
            {active === 4 && (
              <div className="news-content">
                <h5>
                  {" "}
                  Economic Frontiers: Top 10 Businesses Redefining India Read
                </h5>
                <p>
                  In a vibrant display of India's entrepreneurial spirit, The
                  Times of India unveils a compelling narrative on the "Top 10
                  Emerging and Promising Businesses in India 2023."...
                </p>
                <Link
                  className="text-tl_primary text-decoration-none font-semibold"
                  to={
                    "https://yourstory.com/press-release/from-mba-dropout-to-successful-entrepreneur-how-humstudy-founder-imran-khan-turned-zero-investment-into-a-50-lakh-business"
                  }
                >
                  Read more
                </Link>
              </div>
            )}
            {active === 5 && (
              <div className="news-content">
                <h5>
                  Humstudy Emerges as Premier Choice for Indian Students Seeking
                  Abroad Education
                </h5>
                <p>
                  In a remarkable turn of events, Humstudy, a small-sized
                  company, is swiftly ascending to prominence, capturing the
                  attention of Indian students aspiring for education abroad...
                </p>
                <Link
                  className="text-tl_primary text-decoration-none font-semibold"
                  to={
                    "https://www.mid-day.com/amp/brand-media/article/humstudy-emerges-as-premier-choice-for-indian-students-seeking-abroad-education-23313974"
                  }
                >
                  Read more
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
